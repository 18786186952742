/*========================================================================*/
/*===== Variables  */
/* VA Note: hex Upper case*/
/*========================================================================*/
:root {
    /* Core Colors */
    --core-primary-01: #303026;
    --core-primary-02: #F41381;
    --core-primary-03: #07A0C9;
    --core-secondary-01: #FFFFFF;
    --core-secondary-02: #FAF907;
    --core-secondary-03: #F7F7F7;
    --core-secondary-04: #8B8892;
    --core-secondary-05: #875EA0;
    --core-accent-01: #C60E68;
    --core-accent-02: #93C54B;
    --core-accent-03: #067A99;
    --core-accent-04: #E2791B;
    --core-accent-05: #A79BC6;
    --core-accent-06: #11B919;
    --core-accent-07: #F3F0FF;
    --core-accent-08: #D7D607;
    --core-accent-09: #FCB043;
    --core-accent-10: #D9D9D9;
    --core-accent-11: #FFC394;
    --core-accent-12: #FFCBBE;
    --core-accent-13: #FFECCE;
    --core-accent-14: #E1EFF3;
    --core-grey-xlt: var(--core-secondary-01);
    --core-grey-lt: var(--core-secondary-03);
    --core-grey: var(--core-accent-10);
    --core-grey-dark: var(--core-secondary-04);
    --core-grey-xdark: var(--core-primary-01);
    --validation-error-font: var(--core-accent-01);
    --validation-error-bg: transparent;
    --validation-error-border: transparent;
    /* Banners */
    --banner-hero-color: var(--core-secondary-01);
    --banner-color: var(--core-secondary-01);
    /* Buttons Colors*/
    --btn-primary-bg: var(--core-primary-03);
    --btn-primary-font: var(--core-secondary-01);
    --btn-primary-border: transparent;
    --btn-primary-bg-hover: var(--core-accent-03);
    --btn-primary-font-hover: var(--core-secondary-01);
    --btn-primary-border-hover: transparent;
    --btn-primary-bg-focus: var(--core-primary-01);
    --btn-primary-font-focus: var(--core-secondary-01);
    --btn-primary-border-focus: transparent;
    --btn-secondary-bg: var(--core-secondary-03);
    --btn-secondary-font: var(--core-primary-02);
    --btn-secondary-border: var(--core-primary-02);
    --btn-secondary-bg-hover: var(--core-primary-02);
    --btn-secondary-font-hover: var(--core-secondary-01);
    --btn-secondary-border-hover: transparent;
    --btn-secondary-font-focus: var(--core-secondary-01);
    --btn-secondary-bg-focus: var(--core-accent-01);
    --btn-success-bg: var(--core-accent-02);
    --btn-success-font: var(--core-secondary-01);
    --btn-success-border: transparent;
    --btn-success-bg-hover: var(--core-accent-06);
    --btn-success-font-hover: var(--core-secondary-01);
    --btn-success-border-hover: transparent;
    --btn-success-bg-focus: var(--core-secondary-03);
    --btn-success-font-focus: var(--core-accent-06);
    --btn-success-border-focus: transparent;
    --btn-info-bg: var(--core-accent-05);
    --btn-info-font: var(--core-secondary-01);
    --btn-info-border: transparent;
    --btn-info-bg-hover: var(--core-secondary-05);
    --btn-info-font-hover: var(--core-secondary-01);
    --btn-info-border-hover: transparent;
    --btn-info-bg-focus: var(--core-secondary-03);
    --btn-info-font-focus: var(--core-secondary-05);
    --btn-info-border-focus: transparent;
    --btn-warning-bg: var(--core-secondary-02);
    --btn-warning-font: var(--core-primary-01);
    --btn-warning-border: transparent;
    --btn-warning-bg-hover: var(--core-accent-08);
    --btn-warning-font-hover: var(--core-primary-01);
    --btn-warning-border-hover: transparent;
    --btn-warning-bg-focus: var(--core-primary-01);
    --btn-warning-font-focus: var(--core-secondary-01);
    --btn-warning-border-focus: transparent;
    --btn-danger-bg: var(--core-accent-01);
    --btn-danger-font: var(--core-secondary-01);
    --btn-danger-border: transparent;
    --btn-danger-bg-hover: var(--core-accent-15);
    --btn-danger-font-hover: var(--core-secondary-01);
    --btn-danger-border-hover: transparent;
    --btn-danger-bg-focus: var(--core-primary-01);
    --btn-danger-font-focus: var(--core-secondary-01);
    --btn-danger-border-focus: transparent;
    --btn-light-bg: var(--core-secondary-03);
    --btn-light-font: var(--core-primary-03);
    --btn-light-border: transparent;
    --btn-light-bg-hover: var(--core-primary-03);
    --btn-light-font-hover: var(--core-secondary-01);
    --btn-light-border-hover: transparent;
    --btn-light-bg-focus: var(--core-accent-03);
    --btn-light-font-focus: var(--core-secondary-01);
    --btn-light-border-focus: transparent;
    --btn-muted-bg: var(--core-secondary-03);
    --btn-muted-font: var(--core-primary-01);
    --btn-muted-border: transparent;
    --btn-muted-bg-hover: var(--core-secondary-04);
    --btn-muted-font-hover: var(--core-secondary-01);
    --btn-muted-border-hover: transparent;
    --btn-muted-bg-focus: var(--core-primary-01);
    --btn-muted-font-focus: var(--core-secondary-01);
    --btn-muted-border-focus: transparent;
    --btn-dark-bg: var(--core-primary-02);
    --btn-dark-font: var(--core-secondary-01);
    --btn-dark-border: transparent;
    --btn-dark-bg-hover: var(--core-secondary-04);
    --btn-dark-font-hover: var(--core-primary-02);
    --btn-dark-border-hover: transparent;
    --btn-dark-bg-focus: var(--core-secondary-05);
    --btn-dark-font-focus: var(--core-primary-02);
    --btn-dark-border-focus: transparent;
    --btn-link-color: var(--core-primary-03);
    --btn-link-hover: var(--core-accent-03);
    /* Buttons Outline Colors*/
    --btn-outline-primary-border: var(--core-primary-03);
    --btn-outline-primary-font: var(--core-primary-03);
    --btn-outline-primary-bg-hover: var(--core-primary-03);
    --btn-outline-primary-border-hover: transparent;
    --btn-outline-primary-bg-focus: var(--core-accent-03);
    --btn-outline-primary-border-focus: transparent;
    --btn-outline-primary-font-focus: var(--core-secondary-01);
    --btn-outline-secondary-border: var(--core-primary-02);
    --btn-outline-secondary-font: var(--core-primary-02);
    --btn-outline-secondary-bg-hover: var(--core-primary-02);
    --btn-outline-secondary-border-hover: transparent;
    --btn-outline-secondary-bg-focus: var(--core-accent-01);
    --btn-outline-secondary-border-focus: transparent;
    --btn-outline-secondary-font-focus: var(--core-secondary-01);
    --btn-outline-success-border: var(--core-accent-06);
    --btn-outline-success-font: var(--core-accent-06);
    --btn-outline-success-bg-hover: var(--core-accent-06);
    --btn-outline-success-border-hover: transparent;
    --btn-outline-success-bg-focus: var(--core-secondary-04);
    --btn-outline-success-border-focus: transparent;
    --btn-outline-success-font-focus: var(--core-secondary-04);
    --btn-outline-info-border: var(--core-secondary-05);
    --btn-outline-info-font: var(--core-secondary-05);
    --btn-outline-info-bg-hover: var(--core-secondary-05);
    --btn-outline-info-border-hover: transparent;
    --btn-outline-info-font-hover: var(--core-secondary-01);
    --btn-outline-info-bg-focus: var(--core-accent-07);
    --btn-outline-info-border-focus: transparent;
    --btn-outline-info-font-focus: var(--core-secondary-05);
    --btn-outline-warning-border: var(--core-accent-04);
    --btn-outline-warning-font: var(--core-accent-04);
    --btn-outline-warning-bg-hover: var(--core-accent-04);
    --btn-outline-warning-border-hover: transparent;
    --btn-outline-warning-font-hover: var(--core-secondary-01);
    --btn-outline-warning-bg-focus: var(--core-primary-02);
    --btn-outline-warning-border-focus: transparent;
    --btn-outline-warning-font-focus: var(--core-secondary-01);
    --btn-outline-danger-border: var(--core-accent-01);
    --btn-outline-danger-font: var(--core-accent-01);
    --btn-outline-danger-bg-hover: var(--core-accent-01);
    --btn-outline-danger-border-hover: transparent;
    --btn-outline-danger-font-hover: var(--core-secondary-01);
    --btn-outline-danger-bg-focus: var(--core-primary-01);
    --btn-outline-danger-border-focus: transparent;
    --btn-outline-danger-font-focus: var(--core-secondary-01);
    --btn-outline-dark-border: var(--core-primary-01);
    --btn-outline-dark-font: var(--core-primary-01);
    --btn-outline-dark-bg-hover: var(--core-secondary-04);
    --btn-outline-dark-border-hover: transparent;
    --btn-outline-dark-font-hover: var(--core-secondary-01);
    --btn-outline-dark-bg-focus: var(--core-secondary-03);
    --btn-outline-dark-border-focus: transparent;
    --btn-outline-dark-font-focus: var(--core-primary-01);
    --btn-outline-light-border: var(--core-primary-03);
    --btn-outline-light-font: var(--core-primary-03);
    --btn-outline-light-bg-hover: var(--core-primary-01);
    --btn-outline-light-border-hover: transparent;
    --btn-outline-light-font-hover: var(--core-secondary-01);
    --btn-outline-light-bg-focus: var(--core-primary-01);
    --btn-outline-light-border-focus: transparent;
    --btn-outline-light-font-focus: var(--core-secondary-01);
    /* Cards */
    --card-primary-bg: transparent;
    --card-primary-border: transparent;
    /* Card Notification */
    --card-primary-notification-bg: var(--core-secondary-01);
    /* Card Multi Use */
    --card-multi-use-border: var(--core-grey-dark);
    --card-multi-use-bg: var(--core-secondary-01);
    /* Card Video Card */
    --card-video-bg: var(--core-secondary-01);
    --card-video-border: var(--core-grey-lt);
    /* Card Link */
    --card-link-border-hover: var(--core-primary-03);
    /* Shadows */
    --shadow-sm: 0 2px 4px 0 #A6A6A6;
    --shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.29);
    --shadow-dark: 0 3px 20px 1px rgba(0, 0, 0, 0.55);
    --shadow-callout: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
    --shadow-photo: 2px 2px 2px 1px rgba(0, 0, 0, 0.75);
    --shadow-btn: 0px 2px 4px 0px rgba(0, 0, 0, .25);
    --shadow-btn-hover: 2px 2px 0px 0px rgba(0, 0, 0, .75);
    --shadow-inset-lt: 0 1px 11px 0 rgba(0,0,0,0.23);
    --shadow-inset: 0 1px 11px 0 rgba(0,0,0,0.50);
    --shadow-inset-dark: 0 1px 13px 0 rgba(0,0,0,0.83);
    /* Navigation */
    --nav-link-font: var(--core-secondary-01);
    --nav-link-color-mobile: var(--core-accent-03);
    --nav-link-font-hover: var(--core-primary-02);
    --nav-link-border-bottom-color: #DADFEC;
    --nav-success-icon-color: var(--core-secondary-01);
    --nav-error-icon-color: var(--core-accent-01);
    --nav-disabled-icon-color: var(--core-secondary-01);
    --nav-item-font: var(--core-primary-01);
    --nav-item-bg: var(--core-secondary-03);
    --nav-item-bg-hover: var(--core-accent-10);
    --nav-dropdown-bg: var(--core-accent-10);
    --nav-dropdown-item-font: var(--core-primary-01);
    --nav-toggle-color: var(--core-secondary-01);
    --nav-toggle-clicked-color: var(--core-primary-02);
    --nav-toggle-section-bg: #303026;
    /* Footer */
    --footer-primary-bg: var(--core-primary-01);
    --footer-primary-font: var(--core-secondary-01);
    --footer-primary-font-hover: var(--core-primary-01);
    --footer-secondary-bg: var(--core-secondary-01);
    /* Read Only */
    --readonly-font: var(--core-primary-02);
    --readonly-bg: var(--core-secondary-01);
    --readonly-border: var(--core-primary-02);
    /* Font Family*/
    --font-family-primary: 'Montserrat-Regular';
    --font-family-primary-medium: 'Montserrat-Medium';
    --font-family-primary-semibold: 'Montserrat-SemiBold';
    --font-family-primary-bold: 'Montserrat-Bold';
    --font-family-secondary: 'Lora-Regular';
    --font-family-secondary-semibold: 'Lora-SemiBold';
    --font-family-secondary-bold: 'Lora-Bold';
    /* Font Colors*/
    --font-color-xlt: var(--core-secondary-01);
    --font-color-lt: var(--core-primary-01);
    --font-color-dark: var(--core-primary-03);
    --font-color-xdark: var(--core-primary-02);
    --font-color-h1: var(--core-secondary-01);
    --font-color-h2: var(--core-primary-03);
    --font-color-h3-lt: var(--core-primary-01);
    --font-color-h3-xdark: var(--core-primary-02);
    --font-color-h4-lt: var(--core-primary-01);
    --font-color-h4-xdark: var(--core-primary-02);
    --font-color-h5: var(--core-primary-01);
    --font-color-h6: var(--core-primary-02);
    --font-color-p: var(--core-primary-01);
    --font-color-a: var(--core-primary-01);
    --font-color-a-hover: var(--core-secondary-02);
    --font-color-a-visited: var(--core-accent-08);
    /* List */
    --list-header-color: var(--core-accent-03);
    --list-ordered-header-color: #1E2637;
    --list-ordered-number-bg: var(--core-secondary-02);
    --list-ordered-color: var(--core-secondary-01);
    /* FAQ */
    --faq-color-icon: var(--core-primary-01);
    --faq-color-bg: var(--core-secondary-01);
    --faq-color-border: var(--core-grey-lt);
    /* Specialty */
    --company-logo-img: url('../images/logos/atonceprinting_170x80.svg');
    /* Inputs */
    --input-border: var(--core-accent-03);
    --input-bg: var(--core-secondary-03);
    --input-radio-checked-border: var(--core-primary-01);
    --input-invalid-color: var(--core-accent-01);
    /* Breadcrumb */
    --breadcrumb-seperator-color: var(--core-primary-03);
    --breadcrumb-text-color: var(--core-primary-01);
    --breadcrumb-text-color-hover: var(--core-primary-03);
    /* Tooltip */
    --tooltip-color: var(--core-primary-02);
    --tooltip-icon-color: var(--core-primary-02);
    --tooltip-icon-color-hover: var(--core-accent-01);
    --tooltip-icon-color-focus: var(--core-accent-01);
    --tooltip-border: var(--core-primary-02);
    --tooltip-bg: var(--core-secondary-01);
    /* Carousel */
    --carousel-indicator: var(--core-secondary-01);
    --carousel-modal-close-color: var(--core-secondary-01);
    /* Website Custom*/
}


/*========================================================================*/
/*===== Typography  */
/*========================================================================*/
@font-face {
  font-family: 'Montserrat-Regular';
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url(../fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(../fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'Lora-Regular';
  src: url(../fonts/Lora-Regular.ttf);
}

@font-face {
  font-family: 'Lora-SemiBold';
  src: url(../fonts/Lora-SemiBold.ttf);
}

@font-face {
  font-family: 'Lora-Bold';
  src: url(../fonts/Lora-Bold.ttf);
}

.font-primary{
    font-family: var(--font-family-primary) !important;
}

.font-primary-bold{
  font-family: var(--font-family-primary-bold) !important;
}

.font-primary-semibold{
  font-family: var(--font-family-primary-semibold) !important;
}

.font-primary-medium{
  font-family: var(--font-family-primary-medium) !important;
}

.font-secondary{
  font-family: var(--font-family-secondary) !important;
}

.font-secondary-bold{
  font-family: var(--font-family-secondary-bold) !important;
}

.font-secondary-semibold{
  font-family: var(--font-family-secondary-semibold) !important;
}

h1,h2,h3,h4,h5,h6,
.h1Font,.h2Font,.h3Font,.h4Font,.h5Font,.h6Font {
  font-family: var(--font-family-primary);
}

h1,
.h1Font {
  font-size: 3.75rem;
  line-height: 4.5rem;
  font-family: var(--font-family-primary-medium);
  color: var(--font-color-h1);
  margin-bottom: 1rem;
}

h2,
.h2Font {
  font-size: 2.50rem;
  line-height: 3.25rem;
  font-family: var(--font-family-primary-medium);
  color: var(--font-color-h2);
}

h3,
.h3Font {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: var(--font-family-primary);
}

h4,
.h4Font {
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-family: var(--font-family-primary-bold);
}

h5,
.h5Font {
  font-size: 1.125rem;
  line-height: 2.25rem;
  font-family: var(--font-family-primary-bold);
  color: var(--font-color-h5);
}

h6,
.h6Font {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: var(--font-family-primary);
  color: var(--font-color-h6);
}

p, 
.pFont {
  font-size: 1.5rem;
  line-height: 2.75rem;
  font-family: var(--font-family-secondary);
  color: var(--font-color-p);
}

a{
  font-size: 1rem;
  line-height: 2.25rem;
  font-family: var(--font-family-secondary-bold);
  color: var(--font-color-a);
}

a:hover{
  color: var(--font-color-a-hover);
}

@media (max-width: 67.999rem){

  h1,
  .h1Font {
    font-size: 2rem;
    line-height: 3rem;
  }

  h2,
  .h2Font {
    font-size: 1.75rem;
    line-height: 2rem;
    text-align:center!important;
  }

  
  h4,
  .h4Font {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }

  h5,
  .h5Font {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  p, .pFont{
    font-size: 1.125rem;
    line-height: 2.25rem;
  }
}

/*========================================================================*/
/*===== Text Colors */
/*========================================================================*/

.text-muted {
    color: var(--core-accent-10) !important;
}

.text-primary {
    color: var(--core-primary-03) !important;
}

.text-success {
    color: var(--core-accent-02) !important;
}

.text-info {
    color: var(--core-primary-02) !important;
}

.text-warning {
    color: var(--core-accent-08) !important;
}

.text-danger {
    color: var(--core-accent-01) !important;
}

.text-secondary {
    color: var(--core-accent-03) !important;
}

.text-dark {
    color: var(--core-primary-01) !important;
}

.text-white {
    color: var(--core-secondary-01) !important;
}

.text-light {
    color: var(--core-accent-14) !important;
}

/*========================================================================*/
/*===== Borders  */
/*========================================================================*/

.border{
    border-width: 4px !important;
    padding: 1rem;
}

.border-success {
    border-color: var(--core-accent-02) !important;
}

.border-info {
    border-color: var(--core-primary-02) !important;
}

.border-warning {
    border-color: var(--core-accent-03) !important;
}

.border-danger {
    border-color: var(--core-accent-01) !important;
}

.border-secondary {
    border-color: var(--core-accent-03) !important;
}

.border-dark {
    border-color: var(--core-grey-dark) !important;
}

.border-white {
    border-color: var(--core-secondary-01) !important;
}

.border-light {
    border-color: var(--core-accent-14) !important;
}

/*========================================================================*/
/*===== Page Layout  */
/*========================================================================*/
html {
  font-size: 16px;
  box-sizing: border-box;
}

@media (max-width: 35.999rem) { 
  html {
    font-size: 14px;
    box-sizing: border-box;
  }
  
}
html,
body {
  height: 100%;
  width: auto;
}

main {
  margin: auto;
}

.limited-width {
  max-width: 120rem;
}

sup{
    vertical-align:bottom;
    font-size:large;
}

ul{
    list-style:none;
}

/*========================================================================*/
/*===== Custom List  */
/*========================================================================*/

.list-no-indent{
  padding-left: 0;
}

.list-icon-body li {
  padding: 3rem 0;
}

@media (max-width: 61.999rem) {
  .list-icon-body li {
    padding: 0;
  }
  
}

.list-icon {
    display: flex;
    align-items: center;
    gap:1.25rem;
    padding-bottom: 0.50rem;  
}

.list-icon img{
  max-width:5.5rem;
  max-height:6.25rem;
}

.list-icon-sm{
  width:4.75rem !important;
  height:4.25rem !important;
}

.list-icon-lg{
  width:7.25rem !important;
  height:7.25rem !important;
}

.list-icon-header {
    color: var(--list-header-color);
    font-family: var(--font-family-primary-bold);
    font-size: 1.5rem;
}

.list-icon-body p{
  font-size: 1.125rem;
  line-height: 2.25rem;
}

.list-icon-sm + .list-icon-header{
  font-family:var(--font-family-primary-bold) !important;
}

.list-icon-lg + .list-icon-header{
  font-family:var(--font-family-primary-medium) !important;
}

.list-icon-bullet,
.list-icon-bullet .list-icon-header {
    padding: 1rem;
    width: 100%;
    list-style:none;
}

.list-bulletpoint{
  display: flex;
  padding: 10px 0;
  text-align:start;
}

.list-bulletpoint:first-child{
  display: flex;
  padding: 3rem 0 10px 0;
}

.list-bulletpoint::before{
  padding-right: 1.25rem;
  align-content: center;
  content: url(../images/bullet.svg);

}

li.list-numbered-body {
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
}

.list-numbered-header{
  padding-top:.75rem;
  color: var(--list-ordered-header-color);
  font-family: var(--font-family-primary-bold);
  font-size: 1.125rem ;
}

.list-numbered-body p {
  font-size: 1.125rem;
}

.list-number-custom{
  display: grid;
  text-align: center;
  align-items: center;
  align-content: center;
  background-color: var(--list-ordered-number-bg);
  color: var(--list-ordered-color);
  font-family: var(--font-family-primary-bold);
  font-size: 1rem;
  width:3rem;
  height:3rem;
  margin-right: 1.5rem;
  border-radius: 50rem;
}

@media (max-width: 47.999rem) {
    .list-icon-header {
        font-size: 1.125rem;
    }
}

/*========================================================================*/
/*===== FAQ  */
/*========================================================================*/
.faq{
  border-radius: 5px 5px 5px 5px;
  border-top: 1px solid var(--faq-color-border);
  background-color: var(--faq-color-bg);
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 61px;
  outline: none;
  border: none;
}

.faq:hover{
  border-radius: 5px 5px 5px 5px;
  box-shadow: var(--shadow-dark);
}

.faq-active, .faq-active:hover {
    border-radius: 5px 5px 0px 0px !important;
    box-shadow: var(--shadow-dark);
}

.faq-body {
  border-radius: 0px 0px 5px 5px;
  background-color: var(--faq-color-bg);
  overflow: hidden;
  word-wrap: break-word;
  box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.29);
}

.faq-icon, .faq-icon:focus, .faq-icon:active {
  margin-right: 5px;
  margin-bottom: 15px;
  font-size: 2.5rem;
  color: var(--faq-color-icon);
}

.faq-answer {
  padding: 10px 18px;
  word-wrap: break-word;
}
/*========================================================================*/
/*===== Standout  */
/*========================================================================*/

.standout{
  border-radius: 10px;
  padding: 1.15rem;
}

@media (max-width: 47.999rem) {
    .standout {
        padding: 2.15rem;
    }
}

/*========================================================================*/
/*===== Font Awesome  */
/*========================================================================*/
.fa-download {
    color: inherit;
    font-size: inherit;
}

.fa-close-btn {
    color: var(--carousel-modal-close-color);
    font-size: 1.25rem;
    padding-bottom:1rem;
}

/* Tooltip*/
.tooltip-content {
  position: absolute;
  bottom: 120%;
  left: 95%;
  transform: translateX(-50%);
  background-color: var(--tooltip-bg);
  color: var(--tooltip-color);
  padding: 1.75rem;
  border-radius: 1rem;
  font-size: 14px;
  border: 1px solid transparent;
  width: 17.25rem;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .33);
  white-space: nowrap;
}

.tooltip-content::before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 8px;
  border-color: white transparent transparent transparent;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container:focus .fa-circle-question{
  border: 1px solid var(--tooltip-border);
  border-radius: 150rem;
  background-color: var(--tooltip-bg);
  color: var(--tooltip-icon-color-focus) !important;
}

.fa-circle-question{
  border: 1px solid transparent;
  color: var(--tooltip-icon-color);
}

.fa-circle-question:hover{
  border: 1px solid transparent;
  color: var(--tooltip-icon-color-hover);
}

.tooltip-content>h6 {
  text-align: center;
  font-family: var(--font-family-primary-bold);
}

.tooltip-content>.tagline {
  text-align: center;
}

/*========================================================================*/
/*===== Background Colors  */
/*========================================================================*/

.bg-primary {
    background-color: var(--core-primary-03) !important;
}

.bg-secondary {
    background-color: var(--core-accent-03) !important;
}

.bg-info {
    background-color: var(--core-secondary-05) !important;
}

.bg-warning {
    background-color: var(--core-accent-08) !important;
}

.bg-success {
    background-color: var(--core-accent-02) !important;
}

.bg-danger {
    background-color: var(--core-accent-01) !important;
}

.bg-dark {
    background-color: var(--core-primary-01) !important;
}

.bg-light {
    background-color: var(--core-secondary-03) !important;
}

.bg-white {
    background-color: var(--core-secondary-01) !important;
}

/*========================================================================*/
/*===== Navigation Bar  */
/*========================================================================*/
.navbar {
  height: 100px;
  width: 100%;
  padding:0;
}

.navbar-nav{
  gap:1rem;
}

.navbar-nav .dropdown-menu{
  position: relative;
}

.nav-brand {
  padding: .5rem 1rem;
}

.nav-item {
  display: inline-block;
  position: relative;
  align-items: center;
  height: 100px;
}

.nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.5ch;
    font-family: var(--font-family-primary-bold);
    color: var(--nav-link-font);
    white-space: nowrap;
}

.nav-link:focus{
  color:var(--nav-link-font);
}

.nav-link:hover {
  color: var(--nav-link-font-hover);
}

@media (min-width: 61.999rem) { /* VA: Allows for the custom dropdown display on hover*/
    .navbar-nav li:hover > ul.dropdown-menu {
        display: block;
    }
}

@media (max-width: 74.999rem) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .nav-link {
        font-size: 1.25ch;
    }
}

.navbar-nav .nav-link.active, 
.navbar-nav .nav-link.show {  /* VA: Allows for the custom text color on dropdown links*/
  color: var(--core-secondary-01);
}

/* 
  .collapsing,.show{
    z-index: 2;
  } 
*/

.nav-brand-container {
    display: flex;
    width: 41.75rem;
    justify-content: center;
}

.nav-company-logo-size {
  width: 11.75rem;
  height: 3.75rem;
}

.dropdown-toggle i, .dropdown-toggle::after{
  padding-left: .5rem;
  content: none !important;
}
.navbar-toggler{
  border:none;
  height: fit-content;
  font-size: 1.5rem;
  font-family: var(--font-family-primary);
}

.nav-toggle-color{
    color: var(--nav-toggle-color);
}

.nav-toggle-clicked-color {
    color: var(--nav-toggle-clicked-color);
}

.navbar-toggler:focus{
  box-shadow: none;
  border: none;
}

.nav-routes-padding {
    padding: 0 5%;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 61.999rem) {
  .nav-routes-padding{
    padding: 0;
  }
  
  .navbar {
    height: auto;
  }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--core-primary-02);
  }

  .navbar-collapse button{
    text-indent: 1rem;
    background-color: transparent;
  }

  .navbar-collapse{
    padding:0;
    text-indent: 1rem;
  }

  .navbar-nav .dropdown-menu{
    position: static;
  }

  .nav-link {
    font-family: var(--font-family-primary-bold);
    color:var(--nav-link-color-mobile);
  }
  .navbar-nav{
    gap:0rem;
  }
  .nav-brand-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height:80px;
    padding-left: 0;

  }
  .nav-item{
    height: auto;
  }
  .dropdown-menu{
    margin-top: 0 !important;
    border: none!important;
  }
  .dropdown-item{
    height: auto !important;
  }
  .nav-brand{
    display: flex;
    align-items: center;
  }

  .toggle-section {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 1rem;
    background-color: var(--nav-toggle-section-bg);
  }

  .nav-company-logo-size {
    width: 7rem;
    height: 2.75rem;

  }
  
  .nav-link, 
  .nav-link button{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid var(--nav-link-border-bottom-color);
    border-top: 1px solid var(--nav-link-border-bottom-color);
  }

  .navbar > .container-fluid > .container{
    max-width: 100%;
  }

  /* Dropdown */
  .dropdown-menu{
    width: 100% !important;
    border-radius: 0;
    background-color: var(--nav-dropdown-bg);
  }

  .dropdown-toggle i, .dropdown-toggle::after{
    margin-left:auto;
    content: none !important;
    padding-right:1.25rem;
  }
  .dropdown-toggle.show > i{
      transform: rotate(180deg) !important;
      margin-left:auto;
      content: none !important;
  }

  li .dropdown-item, button .dropdown-item, .nav-item .dropdown{
    background-color: transparent;
    color: var(--nav-dropdown-item-font) ;
    font-family: var(--font-family-primary-medium);
    width: 100%;
  }  
  
  li .dropdown-item:hover{
    background-color: inherit;
    color: var(--nav-link-font-hover) ;
    width: 100%;
  }

  .dropdown button{
    padding: 1rem 1rem 1rem 0rem;
  }  
  
  ul.dropdown-menu > li.dropdown button{
    padding: .60rem 1rem .60rem 1rem;
  }

  /* Subdrop */
  .dropdown-menu .dropdown-submenu {
    left: 0% !important;
    top: 0px !important;
    border: none;
}

  .nav-item.dropdown > ul .dropdown-menu{
    background-color: var(--core-secondary-05);
    color: var(--nav-item-font) ;
  }

  .nav-item.dropdown > ul .dropdown-menu > li > a{
    color: var(--nav-item-font) ;
  }  

  .nav-item.dropdown > ul .dropdown-menu > li > a:hover{
    color: var(--nav-link-font-hover);
  }
}

/* Dropdown */

.dropdown-item:active {
  color: var(--nav-item-font);
  text-decoration: none;
  background-color: var(--nav-item-bg);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0; 
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: -0.50px;
}

.dropdown-menu{
  padding:0;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 10%;
  left: 100%;
  margin-top: -1px;
}

.dropdown-menu .dropdown-submenu {
  left: 100%;
  top: -3px;
  margin-left: -1px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
  height: 4rem;
  width: 21.25rem;
  color: var(--nav-item-font);
  font-family: 'Montserrat-Regular';
  background-color: var(--nav-item-bg);
  border-bottom: 1px #CED4DA solid;
}

.dropdown-item:hover {
  background-color: var(--nav-item-bg-hover);
}

/*========================================================================*/
/*===== Footer  */
/*========================================================================*/

/*footer {
  padding-top: 1rem;
}*/

.footer-company-logo-size {
  width: 11.75rem;
  height: 3.75rem;
}

@media (max-width: 50rem) {
  .footer-company-logo-size {
    width: 100%;
    height: 3.75rem;

  }
}

.footer-primary,
.footer-primary a,
.footer-primary p {
  color: var(--core-secondary-01);
  text-decoration: none;
  font-family: 'Montserrat-Bold';
  font-size: 1rem;
  background-color: var(--footer-primary-bg);
}

.footer-secondary,
.footer-secondary a,
.footer-secondary p {
  color: var(--core-primary-01);
  text-decoration: none;
  font-family: 'Montserrat-Bold';
  font-size: 1rem;
  background-color: var(--footer-secondary-bg);
}


/* FOOTER LAYOUT */
.footer-layout {
  display: flex;
  flex-wrap: wrap;
  height:5rem;
  word-break: normal;
  padding: 1rem ;
  gap: 1.5rem;
  align-items: center;
}

.footer-layout a:hover {
  color: var(--footer-primary-font-hover);
}

.footer-socials a, .footer-legal a {
  margin: 0 .25rem;
}

.footer-socials {
  display: flex;
  gap:1rem;
  margin-left: auto;
  align-items: center;
}

.grid-footer-logo {
  display: grid;
  grid-template-rows: 7.25rem;
  grid-template-columns: 30% 40%;
  align-items: center;
  text-align: center;
}
@media (max-width: 47.999rem) {
  .grid-footer-logo {
      display: grid;
      justify-content: center;
      align-content: center;
      grid-template-rows: 7.25rem;
      grid-template-columns: 100%;
      align-items: center;
  }

  .footer-socials {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap:1rem;
      justify-content: center;
      align-items: center;
  }

  .footer-socials a{
      width: 100%;
  }

  .footer-layout{
      justify-content: center;
  }
}

.grid-footer-logo p {
  font-size: 1rem;
  line-height: 1.125rem;
}

/*========================================================================*/
/*===== Banners  */
/*========================================================================*/
.hero-banner {
    background-image: url('../images/banners/home_1920x300.svg');
    height: 18rem;
    color: var(--banner-hero-color);
}

@media (max-width: 47.999rem) {
    .hero-banner {
        background-image: url('../images/banners/mobile/home_390x200.svg');
        height: 12.50rem;
        background-size: cover;
    }
}

.banner {
  height: 12.20rem;
  width: 100%;
  color: var(--banner-color);
}

.banner, .hero-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
}

.banner>img {
  height: auto;
}

/*========================================================================*/
/*===== Breadcrumbs  */
/*========================================================================*/
.breadcrumbs{
  padding: 1rem 0 3.75rem 0;
}

.breadcrumb-item a{
  text-decoration: none;
  font-family: var(--font-family-primary-semibold);
  color: var(--breadcrumb-text-color);
  height: 35px;
  font-size: 1rem;
  line-height: 35px;
  display: inline-flex;
}

li.breadcrumb-item > a:hover{
  color: var(--breadcrumb-text-color-hover) !important;
}

li.crumbs,
.breadcrumb-item,
li.breadcrumb-separator,
li.last {
  list-style: none;
  display: inline-flex;
}

.breadcrumb-separator i{
  margin: auto 6px;
  user-select: none;
  font-size: 1rem;
  color: var(--breadcrumb-seperator-color);
}

/*========================================================================*/
/*===== Grid  */
/*========================================================================*/

/*Two items*/
.grid-two-item {
  display: grid;
  grid-template-areas:
      'first-item'
      'second-item';
  gap: 10px;
  /* Adjust as needed */
}

.grid-two-item>.grid-item:nth-child(1) {
  grid-area: first-item;
}

.grid-two-item>.grid-item:nth-child(2) {
  grid-area: second-item;
}

@media (min-width: 60em) {
  .grid-two-item {
      grid-template-areas:
          'first-item second-item';
  }
}

/* THREE ITEMS */
.grid-three-item .grid-item:nth-child(1) {
  grid-area: first-item;
}

.grid-three-item .grid-item:nth-child(2) {
  grid-area: second-item;
}

.grid-three-item .grid-item:nth-child(3) {
  grid-area: third-item;
}

.grid-three-item {
  display: grid;
  grid-template-areas:
      'first-item'
      'second-item'
      'third-item';
  gap: 10px;
  /* Adjust as needed */
}

@media (min-width: 60em) {
  .grid-three-item {
      grid-template-areas:
          'first-item third-item'
          'second-item third-item';
  }
}

/*Fixed two by two: Regardless of screen elements will be two rows two columns*/
.grid-fixed-two-by-two .grid-item:nth-child(1) {
  grid-area: first-item;
}

.grid-fixed-two-by-two .grid-item:nth-child(2) {
  grid-area: second-item;
}

.grid-fixed-two-by-two .grid-item:nth-child(3) {
  grid-area: third-item;
}

.grid-fixed-two-by-two .grid-item:nth-child(4) {
  grid-area: fourth-item;
}

.grid-fixed-two-by-two {
  display: grid;
  grid-template-areas:
      'first-item second-item'
      'third-item fourth-item';
  gap: 10px;
}

.grid-column-6 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(6, 1fr);
  padding-block: 2rem;
  margin-inline: auto;
  width: 100%;
}

.grid-column-5 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(5, 1fr);
  padding-block: 2rem;
  margin-inline: auto;
  width: 100%;
}

.grid-column-4 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  padding-block: 2rem;
  margin-inline: auto;
  width: 100%;
}

.grid-column-3 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  padding-block: 2rem;
  align-items: center;
}

.grid-column-2 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  padding-block: 2rem;
  margin-inline: auto;
  width: 100%;
}

@media (max-width: 90em) {
  .grid-column-3 {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width:1em) and (max-width: 60em) {
  .grid-column-3 {
      grid-template-columns: repeat(1, 1fr);
  }
}

.grid-col-3-1fr{
  display: grid;
  grid-template-columns: 30% 1fr;
}

.grid-auto {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /*Adjust as needed for column width*/
  padding-block: 1rem;
  width: 100%;
  justify-items: center;
}

.grid-tooltip {
  display: grid;
  grid-template-columns: 7rem 1fr;
  padding-block: .25rem;
  margin-inline: auto;
  width: 100%;
}

/* Custom Grids */
.grid-col-4-2by2{
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  padding-block: 2rem;
  margin-inline: auto;
  width: 100%;
}

.grid-col-2-auto-1fr {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media (max-width: 67.999rem){
  .grid-col-2-auto-1fr {
      display: grid;
      grid-template-columns: 1fr;
  }

  .grid-col-4-2by2{
      display: grid;
      gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr);
  }
}

.grid-alert {
  display: grid;
  grid-template-columns: 40px 1fr;
}

.grid-row-1 {
  grid-row: 1;
}

.grid-row-span-2 {
  grid-row: 1 / span 2;
}


/* Spans */
.grid-col-span-2 {
  grid-column: span 2;
}

.custom-tooltip-container {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.tooltip-container{
    background-color:transparent;
    border:none;
}

.grid-sidebar{
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  width: 100%;
  justify-items: center;
}

.grid-container{
  width: 100%;
  margin: 0 auto;
  padding: 0 auto;
}

/*========================================================================*/
/*===== Carousel  */
/*========================================================================*/

.carousel-indicators{
  bottom: -4rem;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--carousel-indicator);
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}

.carousel-control-next, .carousel-control-prev {
  top: 50%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.carousel-image-item, .carousel-image-item img{
  width:15rem;
}

.grid-carousel-items{
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, 15rem);
    grid-template-rows: auto minmax(256px, 1fr);
    padding-block: 2rem;
    margin-inline: auto;
    width: 100%;
}

.grid-carousel-items button{
    background-color:transparent;
    border:none;
    width:256px;
}


@media (max-width: 47.999rem) { 
  .grid-carousel-items{
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 10rem);
    grid-template-rows: auto;

  }

  .grid-carousel-items button{
    background-color:transparent;
    border:none;
    width:10rem;
  }
  
  .carousel-image-item, .carousel-image-item img{
    width:10rem;
  }
}



/*========================================================================*/
/*===== Buttons  */
/*========================================================================*/

/*============ Shapes ============*/

.btn {
  font-size: 1.125rem;
  font-family: var(--font-family-primary-semibold);
  padding: 1rem 2rem;
}

.rounded-pill {
  border-radius: 2rem;
}

/*================== Sizes ==================*/
.btn-xs {
  padding: .5rem 2rem;
}

@media (max-width: 47.999rem) {
  .btn-xs {
    border-radius: 1.25rem !important;
  }
}

.btn-sm {
  padding: .75rem 2rem;
}

.btn-lg {
  padding: 2rem;
}

/*===================== Button Colors =====================*/
.btn-primary {
  color: var(--btn-primary-font);
  background-color: var(--btn-primary-bg);
  border: 0.25rem solid transparent;
}

.btn-primary:hover {
  color: var(--btn-primary-font-hover);
  background-color: var(--btn-primary-bg-hover);
  border: 0.25rem solid transparent;
}

.btn-primary:focus {
  color: var(--btn-primary-font-focus) !important;
  background-color: var(--btn-primary-bg-focus) !important;
  border: 0.25rem solid var(--btn-primary-bg-focus) !important;
}

.btn-secondary {
  color: var(--btn-secondary-font);
  background-color: var(--btn-secondary-bg);
  border: solid .25rem var(--btn-secondary-border);
}

.btn-secondary:hover {
  color: var(--btn-secondary-font-hover);
  background-color: var(--btn-secondary-bg-hover);
  border: solid .25rem var(--btn-secondary-border);
}

.btn-secondary:focus {
  color: var(--btn-secondary-font-focus) !important;
  background-color: var(--btn-secondary-bg-focus) !important;
  border: solid .25rem var(--btn-secondary-border-hover) !important;
}

.btn-success {
  color: var(--btn-success-font);
  background-color: var(--btn-success-bg);
  border: 0.25rem solid var(--btn-success-border) !important;
}

.btn-success:hover {
  color: var(--btn-success-font-hover);
  background-color: var(--btn-success-bg-hover);
  border: 0.25rem solid var(--btn-success-border-hover) !important;
}

.btn-success:focus {
  color: var(--btn-success-font-focus);
  background-color: var(--btn-success-bg-focus) !important;
  border: 0.25rem solid var(--btn-success-border-focus) !important;
}

.btn-warning {
  color: var(--btn-warning-font);
  background-color: var(--btn-warning-bg);
  border: solid .25rem var(--btn-warning-border);
}

.btn-warning::hover {
  color: var(--btn-warning-font-hover);
  background-color: var(--btn-warning-bg-hover);
  border: solid .25rem var(--btn-warning-border-hover);
}

.btn-warning::focus {
  color: var(--btn-warning-font-focus) !important;
  background-color: var(--btn-warning-bg-focus) !important;
  border: solid .25rem var(--btn-warning-border-focus)!important;
}

.btn-danger {
  color: var(--btn-danger-font);
  background-color: var(--btn-danger-bg);
  border: solid .25rem var(--btn-danger-border);
}

.btn-danger:hover {
  color: var(--btn-danger-font-hover);
  background-color: var(--btn-danger-bg-hover);
  border: solid .25rem var(--btn-danger-border-hover);
}

.btn-danger:focus {
  color: var(--btn-danger-font-focus) !important;
  background-color: var(--btn-danger-bg-focus) !important;
  border: solid .25rem var(--btn-danger-border-focus) !important;
}

.btn-info {
  color: var(--btn-info-font);
  background-color: var(--btn-info-bg);
  border: solid .25rem var(--btn-info-border);
}

.btn-info:hover {
  color: var(--btn-info-font-hover);
  background-color: var(--btn-info-bg-hover);
  border: solid .25rem var(--btn-info-border-hover);
}

.btn-info:focus {
  color: var(--btn-info-font-focus)!important;
  background-color: var(--btn-info-bg-focus) !important;
  border: solid .25rem var(--btn-info-border-focus) !important;
}

.btn-light{
  color: var(--btn-light-font);
  background-color: var(--btn-light-bg);
  border: solid .25rem var(--btn-light-border);
}

.btn-light:hover {
  color: var(--btn-light-font-hover);
  background-color: var(--btn-light-bg-hover);
  border: solid .25rem var(--btn-light-border-hover);
}

.btn-light:focus {
  color: var(--btn-light-font-focus) !important;
  background-color: var(--btn-light-bg-focus) !important;
  border: solid .25rem var(--btn-light-border-focus)!important;
}

.btn-muted {
  color: var(--core-primary-02);
  background-color: var(--btn-muted-bg);
  border: solid .25rem var(--btn-muted-border);
}

.btn-muted:hover {
  color: var(--btn-muted-font-hover);
  background-color: var(--btn-muted-bg-hover);
  border: solid .25rem var(--btn-muted-border-hover) !important;
}

.btn-muted:focus {
  color: var(--btn-muted-font-focus)!important;
  background-color: var(--btn-muted-bg-focus) !important;
  border: solid .25rem var(--btn-muted-border-focus) !important;
}

.btn-dark {
  color: var(--btn-dark-font);
  background-color: var(--btn-dark-bg);
  border: solid .25rem var(--btn-dark-border);
}

.btn-dark:hover {
  color: var(--btn-dark-font-hover);
  background-color: var(--btn-dark-bg-hover);
  border: solid .25rem var(--btn-dark-border-hover);
}

.btn-dark:focus {
  color: var(--btn-dark-font-focus) !important;
  background-color: var(--btn-dark-bg-focus) !important;
  border: solid .25rem var(--btn-dark-border-focus) !important;
}

.btn-link{
  color: var(--btn-link-color);
}

.btn-link:hover{
  color: var(--btn-link-hover);
}

/* ================== BUTTON OUTLINE COLORS ===================*/
.btn-outline-primary{
  color: var(--btn-outline-primary-font);
  border: 0.25rem solid var(--btn-outline-primary-border);
}

.btn-outline-primary:hover{
  background-color: var(--btn-outline-primary-bg-hover);
  border-color: var(--btn-outline-primary-border-hover);
}

.btn-outline-primary:focus{
  color: var(--btn-outline-primary-font-focus);
  background-color: var(--btn-outline-primary-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-primary-border-focus) !important;
}

.btn-outline-secondary{
  color: var(--btn-outline-secondary-font);
  background-color: var(--core-secondary-01);
  border: 0.25rem solid var(--btn-outline-secondary-border);
}

.btn-outline-secondary:hover{
  background-color: var(--btn-outline-secondary-bg-hover);
  border: 0.25rem solid var(--btn-outline-secondary-border-hover);
}

.btn-outline-secondary:focus{
  color: var(--btn-outline-secondary-font-focus) !important;
  background-color: var(--btn-outline-secondary-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-secondary-border-focus) !important;
}

.btn-outline-success{
  color: var(--btn-outline-success-font);
  border: 0.25rem solid var(--btn-outline-success-border);
}

.btn-outline-success:hover{
  background-color: var(--btn-outline-success-bg-hover);
  border: 0.25rem solid var(--btn-outline-success-border-hover);
}

.btn-outline-success:focus{
  color: var(--btn-outline-success-font-focus) !important;
  background-color: var(--btn-outline-success-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-success-border-focus) !important;
}

.btn-outline-info{
  color: var(--btn-outline-info-font);
  border: 0.25rem solid var(--btn-outline-info-border);
}

.btn-outline-info:hover{
  color: var(--btn-outline-info-font-hover);
  background-color: var(--btn-outline-info-bg-hover);
  border: 0.25rem solid var(--btn-outline-info-border-hover);
}

.btn-outline-info:focus{
  color: var(--btn-outline-info-font-focus) !important;
  background-color: var(--btn-outline-info-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-info-border-focus) !important;
}

.btn-outline-warning{
  color: var(--btn-outline-warning-font);
  border: 0.25rem solid var(--btn-outline-warning-border);
}

.btn-outline-warning:hover{
  color: var(--btn-outline-warning-font-hover);
  background-color: var(--btn-outline-warning-bg-hover);
  border: 0.25rem solid var(--btn-outline-warning-border-hover);
}

.btn-outline-warning:focus{
  color: var(--btn-outline-warning-font-focus) !important;
  background-color: var(--btn-outline-warning-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-warning-border-focus) !important;
}

.btn-outline-danger{
  color: var(--btn-outline-danger-font);
  border: 0.25rem solid var(--btn-outline-danger-border);
}

.btn-outline-danger:hover{
  color: var(--btn-outline-danger-font-hover);
  background-color: var(--btn-outline-danger-bg-hover);
  border: 0.25rem solid var(--btn-outline-danger-border-hover);
}

.btn-outline-danger:focus{
  color: var(--btn-outline-danger-font-focus) !important;
  background-color: var(--btn-outline-danger-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-danger-border-focus) !important;
}

.btn-outline-dark{
  color: var(--btn-outline-dark-font);
  border: 0.25rem solid var(--btn-outline-dark-border);
}

.btn-outline-dark:hover{
  color: var(--btn-outline-dark-font-hover);
  background-color: var(--btn-outline-dark-bg-hover);
  border: 0.25rem solid var(--btn-outline-dark-border-hover);
}

.btn-outline-dark:focus{
  color: var(--btn-outline-dark-font-focus) !important;
  background-color: var(--btn-outline-dark-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-dark-border-focus) !important;
}

.btn-outline-light{
  color: var(--btn-outline-light-font) !important;
  border: 0.25rem solid var(--btn-outline-light-border);
}

.btn-outline-light:hover{
  color: var(--btn-outline-light-font-hover)!important;
  background-color: var(--btn-outline-light-bg-hover);
  border: 0.25rem solid var(--btn-outline-light-border-hover);
}

.btn-outline-light:focus{
  color: var(--btn-outline-light-font-focus) !important;
  background-color: var(--btn-outline-light-bg-focus) !important;
  border: 0.25rem solid var(--btn-outline-light-border-focus) !important;
}

.btn-primary:focus,.btn-secondary:focus,
.btn-muted:focus,.btn-dark:focus,
.btn-success:focus,.btn-danger:focus,
.btn-info:focus,.btn-warning:focus,
.btn-light:focus,.btn-outline-light:focus
.btn-outline-primary:focus,
.btn-outline-secondary:focus,
.btn-outline-success:focus,
.btn-outline-info:focus,
.btn-outline-warning:focus,
.btn-outline-danger:focus,
.btn-outline-dark:focus {
  box-shadow: none !important;
}

/*========================================================================*/
/*===== Shadows  */
/*========================================================================*/
.shadow-sm {
  box-shadow: var(--shadow-lt);
}

.shadow {
  box-shadow: var(--shadow);
}

.shadow-lg {
  box-shadow: var(--shadow-dark);
}

.shadow-callout {
  box-shadow: var(--shadow-callout);
}

.shadow-photo {
  box-shadow: var(--shadow-photo);
}

.shadow-btn {
  box-shadow: var(--shadow-btn);
}

.shadow-btn:hover {
  box-shadow: var(--shadow-btn-hover);
}

.shadow-inset-sm{
  box-shadow: var(--shadow-inset-lt);
}

.shadow-inset{
  box-shadow: var(--shadow-inset);
}

.shadow-inset-lg{
  box-shadow: var(--shadow-inset-dark);
}

/*========================================================================*/
/*===== Alerts  */
/*========================================================================*/

.alert{
  padding:1.25rem;
  border-radius: .50rem;
  background-color: var(--core-secondary-01);
}

.alert .title{
  font-family: 'Montserrat-Bold';
  font-family: 1.125rem;
}

.alert .description{
  font-family: 'Lora-Regular';
  font-family: 1.125rem;
}

/************ Alert Colors *************/
.alert-warning{
  border-left: .75rem solid var(--core-accent-03);
  border-color: var(--core-accent-03);
}

.alert-warning .title{
  color: var(--core-accent-03);
}

.alert-success{
  border-left: .75rem solid var(--core-accent-02);
  border-color: var(--core-accent-02);
}

.alert-success .title{
  color: var(--core-accent-02);
}

.alert-info{
  border-left: .75rem solid var(--core-accent-05);
  border-color: var(--core-accent-05);
}

.alert-info .title{
  color: var(--core-accent-05);
}

.alert-danger{
  border-left: .75rem solid var(--core-accent-01);
  border-color: var(--core-accent-01);
}

.alert-danger .title{
  color: var(--core-accent-01);
}
/*========================================================================*/
/*===== Cards  */
/*========================================================================*/

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  padding: .5rem;
}

.card {
  border-radius: .5rem;
  padding: .5rem;
  border: solid 2px var(--card-primary-border);
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: none;
  text-decoration: none;
  background-color: var(--card-primary-bg);
}

.card-basic {
  border: solid 2px var(--card-secondary-border);
  box-shadow: var(--shadow);
}

.card-basic-detail,
.card-link-detail,
.card-link {
  border: solid 2px var(--card-secondary-border);

  .card-body {
    display: none;
  }
}

.card-basic-detail:hover {
  border: solid 5px transparent;
  box-shadow: var(--shadow);

  .card-body {
    display: contents;
  }
}

.card-link+.card-link{
  margin-left: 0;
}

.card-link:hover {
  border: solid 2px var(--core-primary-01);
  box-shadow: var(--shadow);
}

.card-link-detail:hover {
  border: solid 5px var(--core-primary-01);
  box-shadow: var(--shadow);

  .card-body {
    display: contents;
  }
}

.card-link-detail:hover > .card-image, .card-link-detail:hover > .card-header {
    display: none;
}

.card-header {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  font-family: var(--font-family-primary-bold);
}

.card-image{
  width: 11rem;
  height: 11rem;
}

@media (max-width: 67.999rem){
  .card-image{
    width: 100%;
    height: 100%;
  }

  .card-header{
    font-size: 1rem;
  }
}

.card-btn-accordion{
  display: none;
}

@media (max-width: 47.999rem){
    .card-image {
        width: 10.25rem;
        height: 7rem;
        margin-bottom: 1.25rem;
    }

    .card-accordion-title {
        display: flex;
        align-items: center;
    }

    .card-btn-accordion {
        display: contents;
    }

        .card-btn-accordion > button {
            background-color: transparent;
            border: none;
        }

    .card-link-detail:hover .card-image,
    .card-header {
        display: contents !important;
    }

}

.card-footer {
  width: 100%;
  padding: 0;
  background-color: transparent;
  border-top: transparent;
  border: 0;
}

.card-title {
  text-align: start;
  font-family: var(--font-family-primary-semibold);
  color: var(--core-accent-03);
  font-size: 1.5rem;
  margin: 0;
}

.card-sub-title {
  font-size: 1.25rem;
  line-height: 2.25rem;
  color: var(--core-primary-01);
  font-family: var(--font-family-primary-bold);
}

.card-img-top {
  width: 11.5rem;
  height: 10.25rem;
}

@media (max-width: 47.999rem) { 
  .card-img-top {
    width: 7rem;
    height: 7rem;
  }
}


.card-body {
  width: 100%;
  text-align: center;
  font-family: var(--font-family-primary);
  padding:0;
}

.card-text {
  font-size: 1rem;
  line-height: 2.25rem;
  font-family: var(--font-family-secondary);
  color: var(--font-color-p);
  overflow-wrap: anywhere;
}

.card-arrow {
  color: var(--core-primary-01);
  font-size: 1.25rem;
  margin-left: auto;
  line-height: initial;
}

/*=============== CARD SIZE ===============*/

.card-xs {
  width: 10.25rem;
  height: 10.25rem;
}

.card-sm {
  width: 15.5rem;
  height: 13.75rem;
}

.card-lg {
  padding: 3rem;
}

.card-xlg {
  width: 39rem;
  height: 38rem;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 47.999rem) {

  .card-lg {
    padding: 2.5rem 1rem;
  }

  .card-xlg {
    width: 20rem;
    height: 100%;
  }
}

/*=============== CARD TYPES ===============*/

a.card-link {
  text-decoration: none;
}

/******************* CARD INFO *****************/


.card-info {
  display: flex;
  align-items: start;
  text-decoration: none;
  padding: 1rem;
  box-shadow: var(--shadow);
  border: solid 5px transparent;
  height: 100%;
}

.card-info:hover {
  border: solid 5px var(--core-primary-01);
  box-shadow: var(--shadow);
}

.card-info-lg {
  max-width: 32.50rem;
  /* height: 14rem; */
}

.card-info-sm{
  max-width: 32.50rem;
  height:8rem;
}

@media (max-width: 67.999rem){
  .card-info-sm{
    height:100%;
  }
}

.card-info-container{
  height: 100%;
  align-content: center;
  align-items: center;
  display: grid;
}

/******************* CARD VIDEO *****************/

.card.card-video {
  padding: 2.25rem 1.75rem;
}

.card-video {
  width: 25.75rem;
  height: -webkit-fill-available;
  background-color: var(--card-video-bg);
  border: solid 2px var(--card-video-border);
  box-shadow: var(--shadow);
}

.card-video .card-body {
  text-align: start;
  padding: 2rem 0;
  width: 100%;
}

.card-info > .card-body {
  font-size: 1rem;
  font-family: var(--font-family-primary);
  color: var(--core-primary-02);
  flex: none;
  text-align: start;
  padding: 0;
}

/******************* CARD NOTIFICATION *****************/

.card-notification {
  width: 100%;
  /* height: 13.5rem; */
  background-color: var(--card-primary-notification-bg);
  box-shadow: var(--shadow);
  align-items: start !important;
  padding: 2rem;
  justify-content: start !important;
}

.card-notification > .grid-notification > .card-body{
  text-align: start;
  padding: 0 0.50rem;
}

.grid-notification > .card-title {
  line-height: 2.25rem;
  color: var(--core-primary-01);
}

.grid-notification > .card-text {
  line-height: 2.25rem;
  font-size: 1.125rem;
  font-family: var(--font-family-regular);
  color: var(--core-primary-02);
}

/************ Multi Use Card **********/

.card-multi-use{
  background-color: var(--card-multi-use-bg);
  border: solid 2px var(--card-multi-use-border);
  justify-content: start !important;
  /* box-shadow: var(--shadow); */
  padding:2.5rem;
  height: 100%;
  width:100%;
}

.card-multi-use .card-title{
  font-size: 1.5rem;
  font-family: var(--font-family-primary-bold);
  color: var(--core-accent-03);
  text-align: center;
}

.card-multi-use p.card-text {
  font-size: 1.5rem;
  line-height: 2.75rem;
  text-align: start;
}

.card-multi-use-img-start{
  width:1.75rem;
  height:1.75rem;
}

/*================ INPUTS / FORM ================*/

.standout-section{
  background-color: var(--core-accent-14);
  border-radius: 1rem;
  font-size: 1rem;
  padding: .50rem;
  font-family: var(--font-family-secondary);
  width: fit-content;
}

.form-input-padding{
    padding:1.5rem 0;
}

.input-layout{
  display: flex;
  gap: 1.5rem;
}

@media (max-width: 47.999rem) {

  .form-input-padding{
    padding:2.25rem 0;
  }
  
  .input-layout{
    display: inline;
  }
}
input::placeholder {
  color: var(--core-grey);
  font-size: 1rem;
  font-family: var(--font-family-primary);
}

input:invalid, textarea:invalid{
  border: 2px solid var(--input-invalid-color);
}

textarea {
  min-height: 8rem !important;
}

label {
  color: var(--core-primary-03);
  font-size: 1rem;
  font-family: var(--font-family-primary-bold);
}

.asterisk:after {
  color: var(--core-primary-02);
  font-size: 1.125rem;
  font-family: var(--font-family-primary-semibold);
  content: '*';
}

.form-section {
  min-height: 52rem;
  padding: 3.75rem 7.05rem;
  width: 100%;
  border-radius: 40px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.23);
}

.form-control {
  height: 2.75rem;
  border-radius: .25rem;
  border: solid 1px var(--input-border);
  background-color: var(--input-bg);
  margin-top: .50rem;
  padding-left: 1.25rem;
}

.form-select {
  --bs-form-select-bg-img: url(../images/arrowcard-down-arrow_16x16px.svg);
  height: 2.75rem;
  border-radius: .25rem;
  border: solid 1px var(--input-border);
  background-color: var(--input-bg);
  margin-top: .50rem;
  padding-left: 1.25rem;
}

.form-select:focus,
.form-control:focus {
  border: solid 2px var(--core-primary-01);
  box-shadow: none;
}

.form-control-fixed-sm {
  width: 32.5rem;
}

.form-control-fixed-lg {
  width: 66.25rem;
}

.form-check-input[type=radio], .form-check-input[type=checkbox]{
  box-shadow: none;
}
.form-check-input:checked[type=radio] {
  border-color: var(--input-radio-checked-border);
  background-image: url(../images/Radio_filled.svg) !important;
}

.form-check-input:checked[type=radio] {
  border-color: var(--input-radio-checked-border);
  background-image: url(../images/Radio_filled.svg) !important;
  box-shadow: none;
}

/*=== Validation ===*/
.validation-error {
  color: var(--validation-error-color);
}

.input-validation-error {
  border: solid 2px var(--validation-error-color) !important;
  background-color: var(--validation-error-bg) !important;
}

.required-custom-error {
  background-color: var(--core-accent-01) !important;
}

/*========================================================================*/
/*===== Miscellaneous  */
/*========================================================================*/
hr.bold {
  border-top: 4px solid var(--font-color-dark);
}

.hidden,
.hide {
  display: none !important;
}

.no-wrap{
  display: inline-flex;
  flex-wrap:nowrap;
  align-items: baseline;
  justify-content: center;
}

.loader-section{
    height:80vh;
    align-items:center;
}

.spinner-border{
    width:8rem;
    height:8rem;
    --bs-spinner-border-width: .5rem;
}

/* For youtube embeded  */
.embed-responsive{
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item, 
.embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  
    width: 100%;
    height: 75%;
    border: 0;
}

.available-space{
    width: -moz-available;
    height: -moz-available;
}

/*========================================================================*/
/*===== Custom  */
/*========================================================================*/

.pt-6{
  padding-top: 3.75rem;
}

.offcentered-image{
  margin-top: -20%;
}

@media (max-width: 47.999rem) {
    .offcentered-image {
        margin-top: 0%;
    }
}


.grid-padding-lg {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* Columns adjust based on available space */
  padding-block: 5rem;
  width: 100%;
  justify-items: center;
}

.grid-list-auto-2x2{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* Columns adjust based on available space */
  padding-block: 1rem;
  width: 100%;
  justify-items: center;
}

@media (max-width: 47.999rem) {
  .grid-list-auto-2x2 .grid-item:nth-child(1) {
    grid-area: first-item;
  }
  
  .grid-list-auto-2x2 .grid-item:nth-child(2) {
    grid-area: second-item;
  }
  
  .grid-list-auto-2x2 .grid-item:nth-child(3) {
    grid-area: third-item;
  }
  
  .grid-list-auto-2x2 .grid-item:nth-child(4) {
    grid-area: fourth-item;
  }
  
  .grid-list-auto-2x2 {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    display: grid;
    grid-template-areas:
        'first-item second-item'
        'third-item fourth-item';
    gap: 10px;
  }
}

@media (max-width: 47.999rem) {
  .input-gap{
    padding-right: 0rem;
  }
}
/*========================================================================*/
/*===== Images  */
/*========================================================================*/
.company-logo {
  background-image: var(--company-logo-img);
  background-size: contain;
  background-repeat: no-repeat;
}

.youtube-icon {
  background-image: url("../images/youtube.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.youtube-icon-size {
  width: 2.5rem;
  height: 2rem;
}

.girl-computer-icon {
    background-image: url("../images/customer-service_88x88.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 5.375rem;
    width: 5.375rem;
}

.card-notification-icon {
  background-image: url("../images/notifcation_66x66.svg");
  background-repeat: no-repeat;
  background-size: 4rem;
  height: 4rem;
  width:4rem;
}

.banner-contact-us {
    background-image: url("../images/banners/contact-us_1920x200.png");
}

@media (max-width: 47.999rem) {
    .banner-contact-us {
        background-image: url("../images/banners/mobile/contact-us_390x200.png");
    }
}


.bg-our-work{
  background-image: url("../images/bg_blue-w-icons_1920x1057.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height:55rem;
}

@media (max-width: 47.999rem) { 
  .bg-our-work{
    background-image: url("../images/bg_blue-w-icons_390x600.svg");
    height:40rem;
  }
 }


